
/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */

//require('./bootstrap');

//require('countup.js');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//Vue.component('example', require('./components/Example.vue'));

//const app = new Vue({
//    el: '#app'
//});


var jq = jQuery.noConflict();

jq(document).ready(function(){

	setGlobalVars();
	identifyDevice();

	treatNavbar();

	homepage();

    googleMap();

    handleErrorMsgs();

    scrollToQuote();

    ajaxForm();

    fakeSwitch();

    carouselReference(jq(".carouselReference"));

});

jq(window).resize(function() {
    setGlobalVars();
    identifyDevice();
    carouselReference(jq(".carouselReference"));
});

jq(window).scroll(function() {
    if(jq("body.homepage").length && jq("body.desktop").length) {
        var position = jq(this).scrollTop();

        jq('.sectionFullWidth').each(function() {
            var target = jq(this).offset().top;
            var id = jq(this).attr('id');

            if (position >= target-66) {
                jq('nav.navbar ul > li > a').removeClass('active');
                jq('nav.navbar ul > li > a[id=' + id + 'Link]').addClass('active');
            }
        });
    }
});

function setGlobalVars(){
    baseDir = jq(".baseDir").html();

    viewportWidth = jq(window).width();
    viewportHeight = jq(window).height();

    documentHeight = jq(document).height();

    isMobileCheck = false;
};

function identifyDevice() {
    if(viewportWidth<768) {
        jq("body").removeClass("desktop");
        jq("body").removeClass("tablet");
        jq("body").addClass("mobile");
        isMobileCheck = true;
    }else if(768<viewportWidth && viewportWidth<980){
        jq("body").addClass("tablet");
        jq("body").removeClass("desktop");
        jq("body").removeClass("mobile");
    }else {
        jq("body").addClass("desktop");
        jq("body").removeClass("mobile");
        jq("body").removeClass("tablet");
    }
};

function treatNavbar() {
    jq(window).scroll(function(){
        if(jq(window).scrollTop() > jq(".topBar").height()) {
            jq("header").addClass("fixedPos");
        }else{
            jq("header").removeClass("fixedPos");
        }
    });
};

function smoothScroll(element,target) {
    if(element.hasClass("scrollTo")) {
        element.click(function(e) {
            e.preventDefault();
            jq('html, body').animate({
                scrollTop: target.offset().top - (jq("header").height()-1)
            }, 600);
        });
    }
};

function numberFormatted(amount) {
    var i = parseFloat(amount);
    if(isNaN(i)) { i = 0.00; }
    var minus = '';
    if(i < 0) { minus = '-'; }
    i = Math.abs(i);
    i = parseInt((i + .005) * 100);
    i = i / 100;
    s = new String(i);
    if(s.indexOf('.') < 0) { s += '.00'; }
    if(s.indexOf('.') == (s.length - 2)) { s += '0'; }
    s = minus + s;
    s = s.replace(/\s/g, "").replace(".", ",");
    return s;
};

function carousel(carouselObject){
	carouselObject.slick({
        dots: true,
        arrows: false,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 6500
    });
};

function carouselReference(carouselObject){
    if(jq('body.mobile').length){
        carouselObject.slick({
            dots: true,
            arrows: false,
            speed: 1000,
            autoplay: true,
            autoplaySpeed: 5500,
            slidesToShow: 1,
            slidesToScroll: 1,
        });
    }else{
        carouselObject.slick({
            dots: true,
            arrows: false,
            speed: 1000,
            autoplay: true,
            autoplaySpeed: 5500,
            slidesToShow: 2,
            slidesToScroll: 2,
        });
    }
};

function carouselDetailed(carouselObject){
    if(jq('body.mobile').length){
        carouselObject.slick({
            dots: true,
            arrows: false,
            speed: 1000,
            autoplay: true,
            autoplaySpeed: 5500,
            slidesToShow: 1,
            slidesToScroll: 1,
        });
    }else{
        carouselObject.slick({
            dots: true,
            arrows: false,
            speed: 1000,
            autoplay: true,
            autoplaySpeed: 5500,
            slidesToShow: 2,
            slidesToScroll: 2,
        });
    }
};

function homepage() {
    if(jq("body.homepage").length){
        if(jq("body.desktop").length){
            jq("nav.navbar a.contact").addClass("scrollTo");
            //smoothScroll(jq("nav.navbar a.services"),jq(".sectionServices"));
            //smoothScroll(jq("nav.navbar a.references"),jq(".sectionReferences"));
            //smoothScroll(jq("nav.navbar a.about"),jq(".sectionAbout"));
            smoothScroll(jq("nav.navbar a.contact"),jq("footer .lower"));
        }
        //carousel(jq(".carousel"));
        carouselDetailed(jq(".carousel"));
        smoothScroll(jq(".homepageCover a.scrollTo"),jq(".sectionServices"));
    } else {
        if(jq("body.desktop").length){
            jq("nav.navbar a.contact").addClass("scrollTo");
            smoothScroll(jq("nav.navbar a.contact"),jq("footer .lower"));
        }
    }
};

function fakeSwitch() {
    jq(".fakeSwitch").featherlight({
        targetAttr: 'href'
    });
}

function scrollToQuote() {
    smoothScroll(jq(".scrollToQuote"),jq("footer .lower"));
};

function googleMap() {
    jq('.mapBlock').click(function(){
        jq(this).find('iframe').addClass('clicked');
    })
    .mouseleave(function(){
        jq(this).find('iframe').removeClass('clicked');
    });
};

function handleErrorMsgs() {
    if(jq('.errorMsg').length){
        jq('.errorMsg').each(function(){
            var thisHeight = jq(this).height();
            jq(this).css("top",-thisHeight);
            jq(this).wrapInner("<div class='inner'></div>");
            jq(this).find(".inner").prepend("<div class='close'></div>");
            jq(this).append("<div class='pointer'></div>");
        });
        jq('html, body').animate({ scrollTop: ( jq('.errorMsg').offset().top )+70 });
    }
};

function scrollToElement() {
    var hash = window.location.hash;
    jq('html, body').animate({ scrollTop: (jq(hash).offset().top)+50 });
}

function ajaxForm() {

    var url = "/contact-us";

    //display modal form for task editing
    jq('.open-modal').click(function(){
        var task_id = jq(this).val();

        jq.get(url + '/' + task_id, function (data) {
            //success data
            console.log(data);
            jq('#task_id').val(data.id);
            jq('#task').val(data.task);
            jq('#description').val(data.description);
            jq('#btn-save').val("update");

            jq('#myModal').modal('show');
        }) 
    });

    //display modal form for creating new task
    jq('#btn-add').click(function(){
        jq('#btn-save').val("add");
        jq('#frmTasks').trigger("reset");
        jq('#myModal').modal('show');
    });

    //delete task and remove it from list
    jq('.delete-task').click(function(){
        var task_id = jq(this).val();

        jq.ajax({

            type: "DELETE",
            url: url + '/' + task_id,
            success: function (data) {
                console.log(data);

                jq("#task" + task_id).remove();
            },
            error: function (data) {
                console.log('Error:', data);
            }
        });
    });

    //create new task / update existing task
    jq("#btn-save").click(function (e) {
        jq.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': jq('meta[name="_token"]').attr('content')
            }
        })

        e.preventDefault(); 

        var formData = {
            task: jq('#task').val(),
            description: jq('#description').val(),
        }

        //used to determine the http verb to use [add=POST], [update=PUT]
        var state = jq('#btn-save').val();

        var type = "POST"; //for creating new resource
        var task_id = jq('#task_id').val();;
        var my_url = url;

        if (state == "update"){
            type = "PUT"; //for updating existing resource
            my_url += '/' + task_id;
        }

        console.log(formData);

        jq.ajax({

            type: type,
            url: my_url,
            data: formData,
            dataType: 'json',
            success: function (data) {
                console.log(data);

                var task = '<tr id="task' + data.id + '"><td>' + data.id + '</td><td>' + data.task + '</td><td>' + data.description + '</td><td>' + data.created_at + '</td>';
                task += '<td><button class="btn btn-warning btn-xs btn-detail open-modal" value="' + data.id + '">Edit</button>';
                task += '<button class="btn btn-danger btn-xs btn-delete delete-task" value="' + data.id + '">Delete</button></td></tr>';

                if (state == "add"){ //if user added a new record
                    jq('#tasks-list').append(task);
                }else{ //if user updated an existing record

                    jq("#task" + task_id).replaceWith( task );
                }

                jq('#frmTasks').trigger("reset");

                jq('#myModal').modal('hide')
            },
            error: function (data) {
                console.log('Error:', data);
            }
        });
    });
}
